import './preConfig';

import { env } from './env';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { getEnv, getLocalStorageData } from './utils';

import { EventType } from '@azure/msal-browser';
import { msalInstance, loginRequest } from './authConfig';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (env.REACT_APP_TEST_MODE) {
  root.render(<App />);
} else {
  Sentry.init({
    environment: getEnv(),
    dsn: 'https://54c23e4477284a19f7b431d1292ab40a@o4506547652984832.ingest.sentry.io/4506548060946432',
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    try {
      const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });

      if (!response.error) {
        const userData = {
          token: response.accessToken,
          refreshToken: Date.parse(response.expiresOn) - 5 * 60000,
          ...response.account,
        };
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        localStorage.removeItem('user');
        msalInstance.logout();
      }
    } catch (e) {
      localStorage.removeItem('user');
      msalInstance.logout();
    }
  }

  msalInstance.addEventCallback(
    event => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const userData = {
          token: event.payload.accessToken,
          refreshToken: Date.parse(event.payload.expiresOn) - 5 * 60000,
          ...event.payload.account,
        };

        msalInstance.setActiveAccount(event.payload.account);
        localStorage.setItem('user', JSON.stringify(userData));
      }
      if (event.eventType === EventType.LOGOUT_SUCCESS) {
        const notifications = getLocalStorageData('notifications', []);
        const ganttFilters = getLocalStorageData('ganttFilters', []);
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem('ganttFilters', JSON.stringify(ganttFilters));
        localStorage.setItem('notifications', JSON.stringify(notifications));
      }
      if (event.eventType === EventType.ACCOUNT_REMOVED) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
    error => {
      console.log('error', error);
    }
  );

  msalInstance
    .handleRedirectPromise()
    .then(tokenResponse => {
      const account = msalInstance.getActiveAccount();
      if (!tokenResponse) {
        // No user signed in
        if (!account) msalInstance.loginRedirect(loginRequest);
      } else {
        // Do something with the tokenResponse
      }
    })
    .catch(error => {
      // Handle error
      msalInstance.loginRedirect(loginRequest);
      console.error('error', error);
    });

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  root.render(
    // <StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
    // </StrictMode>
  );
}
