import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getLocalStorageData } from '../utils';

const trackAndTraceFilters = getLocalStorageData('trackAndTraceFilters', {
  fleet: null,
  earlyLate: [-6, 6],
  dwell: [0, 24],
  deadhead: [0, 1000],
  ptaLocation: null,
  ptaLocationRadius: null,
  currentLocation: null,
  currentLocationRadius: null,
  truckStatuses: [],
});

const totalTruckStatuses = trackAndTraceFilters.truckStatuses.length;

const total =
  Number(trackAndTraceFilters.fleet ? 1 : 0) +
  Number(trackAndTraceFilters.earlyLate[0] !== -6 || trackAndTraceFilters.earlyLate[1] !== 6 ? 1 : 0) +
  Number(trackAndTraceFilters.dwell[0] || trackAndTraceFilters.dwell[1] !== 24 ? 1 : 0) +
  Number(trackAndTraceFilters.deadhead[0] || trackAndTraceFilters.deadhead[1] !== 1000 ? 1 : 0) +
  Number(trackAndTraceFilters.ptaLocation ? 1 : 0) +
  Number(trackAndTraceFilters.ptaLocationRadius ? 1 : 0) +
  Number(trackAndTraceFilters.currentLocation ? 1 : 0) +
  Number(trackAndTraceFilters.currentLocationRadius ? 1 : 0) +
  totalTruckStatuses;

const initialState = Object.freeze({
  trackAndTraceFilters,
  totalTruckStatuses,
  total,
});

const trackAndTraceSlice = createSlice({
  name: 'trackAndTrace',
  initialState,
  reducers: {
    setItem(state, action) {
      const newItem = action.payload;

      state.trackAndTraceFilters = newItem;

      const totalTruckStatuses = (state.totalTruckStatuses = newItem.truckStatuses.length);

      state.total =
        Number(newItem.fleet ? 1 : 0) +
        Number(newItem.earlyLate[0] !== -6 || newItem.earlyLate[1] !== 6 ? 1 : 0) +
        Number(newItem.dwell[1] !== 24 || newItem.dwell[0] ? 1 : 0) +
        Number(newItem.deadhead[1] !== 1000 || newItem.deadhead[0] ? 1 : 0) +
        Number(newItem.ptaLocation ? 1 : 0) +
        Number(newItem.ptaLocationRadius ? 1 : 0) +
        Number(newItem.currentLocation ? 1 : 0) +
        Number(newItem.currentLocationRadius ? 1 : 0) +
        totalTruckStatuses;

      localStorage.setItem('trackAndTraceFilters', JSON.stringify(newItem));
    },
  },
});

export const trackAndTraceActions = trackAndTraceSlice.actions;
export default trackAndTraceSlice;
