import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: [] };

const clusterAnalysisSlice = createSlice({
  name: 'clusterAnalysis',
  initialState,
  reducers: {
    setItem(state, action) {
      state.data = action.payload;
    },
  },
});

export const clusterAnalysisActions = clusterAnalysisSlice.actions;
export default clusterAnalysisSlice;
