export const DEFAULT_ROUTE = '/simple-fleet';

/**
 * The path length is a static value based on the design specifications
 * for calculating the total offset of the arc in the clock component.
 * The clock component is designed to be 40px wide and this value ensures
 * it matches the design precisely. It was tested for consistent behavior
 * under browser zoom and pinch zoom.
 */
export const PATH_LENGTH = 65.98274230957031;
