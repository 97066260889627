import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../utils';

const fleetFilters = getLocalStorageData('fleetFilters', {
  carriers: [],
  fleetType: [],
  driverStatus: [],
  utilization: [],
  critical: false,
  assignmentOriginCity: [],
  assignmentDestinationCity: [],
  manifestIds: [],
});

const totalCarriers = fleetFilters.carriers.length;
const totalFleetType = fleetFilters.fleetType.length;
const totalDriverStatus = fleetFilters.driverStatus.length;
const totalUtilization = fleetFilters.utilization.length;
const totalAssignmentOriginCity = fleetFilters.assignmentOriginCity.length;
const totalAssignmentDestinationCity = fleetFilters.assignmentDestinationCity.length;
const totalManifestIds = fleetFilters.manifestIds.length;
const fleetTypeOptions = [];
const total =
  totalCarriers +
  totalFleetType +
  totalDriverStatus +
  totalUtilization +
  totalAssignmentOriginCity +
  totalAssignmentDestinationCity +
  totalManifestIds +
  (fleetFilters.critical ? 1 : 0);

const initialState = Object.freeze({
  fleetFilters,
  totalCarriers,
  totalFleetType,
  totalDriverStatus,
  totalUtilization,
  total,
});

const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    setItem(state, action) {
      const newItem = action.payload;

      state.fleetFilters = newItem;

      state.totalCarriers = newItem.carriers.length;
      state.totalFleetType = newItem.fleetType.length;
      state.totalDriverStatus = newItem.driverStatus.length;
      state.totalUtilization = newItem.utilization.length;
      state.totalAssignmentOriginCity = newItem.assignmentOriginCity.length;
      state.totalAssignmentDestinationCity = newItem.assignmentDestinationCity.length;
      state.totalManifestIds = newItem.manifestIds.length;
      state.total =
        newItem.carriers.length +
        newItem.fleetType.length +
        newItem.driverStatus.length +
        newItem.utilization.length +
        newItem.assignmentOriginCity.length +
        newItem.assignmentDestinationCity.length +
        newItem.manifestIds.length +
        (newItem.critical ? 1 : 0);

      localStorage.setItem('fleetFilters', JSON.stringify(newItem));
    },
  },
});

export const fleetActions = fleetSlice.actions;
export default fleetSlice;
