import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import AuthGuard from '../guards/AuthGuard';
import Layout from '../components/Layout';
import Loading from '../components/UI/Loading';
import { ReactComponent as FleetIconSvg } from '../assets/icons/mfleet.svg';
import { ReactComponent as FreightsIconSvg } from '../assets/icons/mfreights.svg';
import { ReactComponent as ExternalIconSvg } from '../assets/icons/mexternal.svg';
import { ReactComponent as TrackAndTraceIconSvg } from '../assets/icons/mtrackandtrace.svg';
import { ReactComponent as TimelineIconSvg } from '../assets/icons/mtimeline.svg';
import HelpIcon from '@mui/icons-material/HelpOutline';
import AppsIcon from '@mui/icons-material/Apps';
import UsersIcon from '@mui/icons-material/People';
import { getEnv, getLocalStorageData } from '../utils';
import { DEFAULT_ROUTE } from '../consts';

// Get user's preferred default route or fall back to system default
export const getDefaultRoute = () => {
  const userDefault = getLocalStorageData('userDefaultRoute', null, false);
  // Only use user preference if it's a valid route
  if (
    userDefault &&
    routeConfig.routes[Object.keys(routeConfig.routes).find(key => routeConfig.routes[key].path === userDefault)]
  ) {
    return userDefault;
  }
  return DEFAULT_ROUTE;
};

// Set user's preferred default route
export const setUserDefaultRoute = path => {
  if (path && routeConfig.routes[Object.keys(routeConfig.routes).find(key => routeConfig.routes[key].path === path)]) {
    localStorage.setItem('userDefaultRoute', path); // TODO: use store userConfigSlice if required
    return true;
  }
  return false;
};

// Lazy load pages
const SimpleFleetPage = lazy(() => import('../pages/SimpleFleetPage'));
const SimpleFreightPage = lazy(() => import('../pages/SimpleFreightPage'));
const FleetPage = lazy(() => import('../pages/FleetPage'));
const UsersPage = lazy(() => import('../pages/UsersPage'));
const StyleguidePage = lazy(() => import('../pages/StyleguidePage'));
const SwaggerPage = lazy(() => import('../pages/SwaggerPage'));
const TrackAndTracePage = lazy(() => import('../pages/TrackAndTracePage'));
const ClusterAnalysisPage = lazy(() => import('../pages/ClusterAnalysis'));
const ClusterAnalysisFilter = lazy(() => import('../pages/ClusterAnalysisFilter'));

const wrapInLayout = (Component, className = '') => (
  <AuthGuard
    component={
      <Provider store={store}>
        <Layout className={className}>
          <Suspense fallback={<Loading type="route" />}>
            <Component />
          </Suspense>
        </Layout>
      </Provider>
    }
  />
);

export const routeConfig = {
  get defaultRoute() {
    return getDefaultRoute();
  },
  routes: {
    trackAndTrace: {
      path: '/track-and-trace',
      component: () => wrapInLayout(TrackAndTracePage, 'track-and-trace'),
      icon: TrackAndTraceIconSvg,
      text: 'Track & Trace',
      testId: 'main-menu_track-and-trace_button',
      showInMenu: false,
    },
    simpleFleet: {
      path: '/simple-fleet',
      component: () => wrapInLayout(SimpleFleetPage, 'simple-fleet'),
      icon: TimelineIconSvg,
      text: 'Simple Fleet',
      testId: 'main-menu_simple-fleet_button',
      showInMenu: true,
    },
    clusterAnalysis: {
      path: '/cluster-analysis',
      component: () => wrapInLayout(ClusterAnalysisPage, 'cluster-analysis'),
      icon: TimelineIconSvg,
      text: 'Cluster Analysis',
      testId: 'main-menu_cluster_analysis_button',
      showInMenu: false,
    },
    ClusterAnalysisFilter: {
      path: '/cluster-analysis/data',
      component: () => wrapInLayout(ClusterAnalysisFilter, 'cluster-analysis-filter'),
      icon: TimelineIconSvg,
      text: 'Cluster Analysis',
      testId: 'main-menu_cluster_analysis_button',
      showInMenu: false,
    },
    freight: {
      path: '/?layers=0100',
      component: () => wrapInLayout(FleetPage, 'freight'),
      icon: FreightsIconSvg,
      text: 'Freight',
      testId: 'main-menu_freight_button',
      showInMenu: false,
    },
    simpleFreight: {
      path: '/simple-freight',
      component: () => wrapInLayout(SimpleFreightPage, 'simple-freight'),
      icon: TimelineIconSvg,
      text: 'Simple Freight',
      testId: 'main-menu_simple-freight_button',
      showInMenu: true,
    },
    fleet: {
      path: '/fleet',
      component: () => wrapInLayout(FleetPage, 'fleet'),
      icon: FleetIconSvg,
      text: 'Fleet',
      testId: 'main-menu_fleet_button',
      showInMenu: true,
    },
    externalFreight: {
      path: '/?layers=0010',
      component: () => wrapInLayout(FleetPage, 'external-freight'),
      icon: ExternalIconSvg,
      text: 'External Freight',
      testId: 'main-menu_external-freight_button',
      showInMenu: false,
    },
    users: {
      path: '/users',
      component: () => wrapInLayout(UsersPage, 'users'),
      icon: UsersIcon,
      text: 'Users',
      testId: 'main-menu_users_button',
      showInMenu: true,
      isDev: true,
    },
    styleguide: {
      path: '/styleguide',
      component: () => wrapInLayout(StyleguidePage, 'styleguide'),
      icon: AppsIcon,
      text: 'Style Guide',
      testId: 'main-menu_style-guide_button',
      showInMenu: true,
      isDev: true,
    },
    docs: {
      path: '/docs',
      component: () => wrapInLayout(SwaggerPage, 'docs'),
      icon: HelpIcon,
      text: 'Documentation',
      testId: 'main-menu_docs_button',
      showInMenu: true,
      isDev: true,
    },
  },
};

// Helper function to get menu items
export const getMenuItems = (includeDevRoutes = false) => {
  return Object.entries(routeConfig.routes)
    .filter(([_, config]) => {
      if (!config.showInMenu) return false;
      if (config.isDev && !includeDevRoutes) return false;
      return true;
    })
    .map(([key, config]) => ({
      key,
      path: config.path,
      icon: <config.icon />,
      text: config.text,
      testId: config.testId,
    }));
};
