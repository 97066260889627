import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../utils';

const freightFilters = getLocalStorageData('freightFilters', {
  dateRange: [null, null],
  edges: null,
  possibleEdges: null,
  eventType: 'all-events',

  originCity: [],
  originState: [],
  destinationCity: [],
  destinationState: [],

  locationEventCount: [0, 0],
  manifestStatuses: [],
  manifestIds: [],
  weightRange: [0, 0],
  lengthOfHaul: [0, 0],
  ratePerMile: [0, 0],

  carriers: [],
  carrierTypes: [],
  critical: false,
});

const totalOriginCity = freightFilters.originCity.length;
const totalOriginState = freightFilters.originState.length;
const totalDestinationCity = freightFilters.destinationCity.length;
const totalDestinationState = freightFilters.destinationState.length;
const totalLocation =
  totalOriginCity +
  totalOriginState +
  totalDestinationCity +
  totalDestinationState +
  Number(freightFilters.locationEventCount[0] || freightFilters.locationEventCount[1] ? 1 : 0) +
  Number(freightFilters.eventType !== 'all-events' ? 1 : 0);

const totalManifestStatuses = freightFilters.manifestStatuses.length;
const totalManifestIds = freightFilters.manifestIds.length;
const totalManifests =
  totalManifestStatuses +
  totalManifestIds +
  Number(freightFilters.weightRange[0] || freightFilters.weightRange[1] ? 1 : 0) +
  Number(freightFilters.lengthOfHaul[0] || freightFilters.lengthOfHaul[1] ? 1 : 0) +
  Number(freightFilters.ratePerMile[0] || freightFilters.ratePerMile[1] ? 1 : 0);

const totalCarrierTypes = freightFilters.carrierTypes.length;
const totalCarriers = freightFilters.carriers.length;
const totalBusinessEntity = totalCarrierTypes + totalCarriers;

const total =
  Number(freightFilters.dateRange[0] || freightFilters.dateRange[1] ? 1 : 0) +
  Number(freightFilters.edges ? 1 : 0) +
  Number(freightFilters.possibleEdges ? 1 : 0) +
  totalLocation +
  totalManifests +
  totalBusinessEntity +
  (freightFilters.critical ? 1 : 0);

const initialState = Object.freeze({
  freightFilters,
  totalOriginCity,
  totalOriginState,
  totalDestinationCity,
  totalDestinationState,
  totalLocation,
  totalManifestStatuses,
  totalManifestIds,
  totalManifests,
  totalCarrierTypes,
  totalCarriers,
  totalBusinessEntity,
  total,
});

const freightSlice = createSlice({
  name: 'freight',
  initialState,
  reducers: {
    setItem(state, action) {
      const newItem = action.payload;

      state.freightFilters = newItem;

      state.totalOriginCity = newItem.originCity.length;
      state.totalOriginState = newItem.originState.length;
      state.totalDestinationCity = newItem.destinationCity.length;
      state.totalDestinationState = newItem.destinationState.length;
      const totalLocation = (state.totalLocation =
        newItem.originCity.length +
        newItem.originState.length +
        newItem.destinationCity.length +
        newItem.destinationState.length +
        Number(newItem.locationEventCount[0] || newItem.locationEventCount[1] ? 1 : 0) +
        Number(newItem.eventType !== 'all-events' ? 1 : 0));

      state.totalManifestStatuses = newItem.manifestStatuses.length;
      state.totalManifestIds = newItem.manifestIds.length;
      const totalManifests = (state.totalManifests =
        newItem.manifestStatuses.length +
        newItem.manifestIds.length +
        Number(newItem.weightRange[0] || newItem.weightRange[1] ? 1 : 0) +
        Number(newItem.lengthOfHaul[0] || newItem.lengthOfHaul[1] ? 1 : 0) +
        Number(newItem.ratePerMile[0] || newItem.ratePerMile[1] ? 1 : 0));

      state.totalCarrierTypes = newItem.carrierTypes.length;
      state.totalCarriers = newItem.carriers.length;
      const totalBusinessEntity = (state.totalBusinessEntity = newItem.carrierTypes.length + newItem.carriers.length);

      state.total =
        Number(newItem.dateRange[0] || newItem.dateRange[1] ? 1 : 0) +
        Number(newItem.edges ? 1 : 0) +
        Number(newItem.possibleEdges ? 1 : 0) +
        totalLocation +
        totalManifests +
        totalBusinessEntity +
        (newItem.critical ? 1 : 0);

      localStorage.setItem('freightFilters', JSON.stringify(newItem));
    },
  },
});

export const freightActions = freightSlice.actions;
export default freightSlice;
