import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../utils';

const externalFreightFilters = getLocalStorageData('externalFreightFilters', {
  originCity: [],
  originState: [],
  destinationCity: [],
  destinationState: [],
  manifestIds: [],
  locationEventCount: [0, 0],
});

const totalOriginCity = externalFreightFilters.originCity.length;
const totalOriginState = externalFreightFilters.originState.length;
const totalDestinationCity = externalFreightFilters.destinationCity.length;
const totalDestinationState = externalFreightFilters.destinationState.length;
const totalManifestIds = externalFreightFilters.manifestIds.length;
const total =
  totalOriginCity +
  totalOriginState +
  totalDestinationCity +
  totalDestinationState +
  totalManifestIds +
  Number(externalFreightFilters.locationEventCount[0] || externalFreightFilters.locationEventCount[1] ? 1 : 0);

const initialState = Object.freeze({
  externalFreightFilters,
  totalOriginCity,
  totalOriginState,
  totalDestinationCity,
  totalDestinationState,
  totalManifestIds,
  total,
});

const externalFreightSlice = createSlice({
  name: 'externalFreight',
  initialState,
  reducers: {
    setItem(state, action) {
      const newItem = action.payload;

      state.externalFreightFilters = newItem;

      state.totalOriginCity = newItem.originCity.length;
      state.totalOriginState = newItem.originState.length;
      state.totalDestinationCity = newItem.destinationCity.length;
      state.totalDestinationState = newItem.destinationState.length;
      state.totalManifestIds = newItem.manifestIds.length;

      state.total =
        newItem.originCity.length +
        newItem.originState.length +
        newItem.destinationCity.length +
        newItem.destinationState.length +
        newItem.manifestIds.length +
        Number(newItem.locationEventCount[0] || newItem.locationEventCount[1] ? 1 : 0);

      localStorage.setItem('externalFreightFilters', JSON.stringify(newItem));
    },
  },
});

export const externalFreightActions = externalFreightSlice.actions;
export default externalFreightSlice;
