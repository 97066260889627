import { configureStore } from '@reduxjs/toolkit';
import serviceSlice from './serviceSlice';
import fleetSlice from './fleetSlice';
import freightSlice from './freightSlice';
import layerSlice from './layerSlice';
import markersSlice from './markersSlice';
import paramsSlice from './paramsSlice';
import externalFreightSlice from './externalFreightSlice';
import trackAndTraceSlice from './trackAndTraceSlice';
import userConfigSlice from './userConfigSlice';
import notificationsSlice from './notificationsSlice';
import clusterAnalysisSlice from './clusterAnalysisSlice';

export const store = configureStore({
  reducer: {
    services: serviceSlice.reducer,
    params: paramsSlice.reducer,
    fleet: fleetSlice.reducer,
    freight: freightSlice.reducer,
    externalFreight: externalFreightSlice.reducer,
    trackAndTrace: trackAndTraceSlice.reducer,
    layer: layerSlice.reducer,
    markers: markersSlice.reducer,
    userConfig: userConfigSlice.reducer,
    notifications: notificationsSlice.reducer,
    clusterAnalysis: clusterAnalysisSlice.reducer,
    // Add other reducers or middleware here if needed
    // middleware: []
  },
});

export default { store };
